import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "The Federalists created a ton of new taxes, and no one likes them",
    img: "sadcat.jpeg",
    options: [
      {text: 'Taxation is theft, remove the taxes!', value: 25},
      {text: "Keep the taxes, we need to make money somehow", value: 0},
    ]
  },
  {
    prompt: "North African pirates are demanding tribute for the privilege of not having your ships attacked",
    img: "jacksparrow.jpeg",
    options: [
      {text: "Give them the money", value: 5 },
      {text: "We do not negotiate with terrorists, or pirates", value: 25},
    ]
  },
  {
    prompt: "France wants to sell you a ton of land that will double the size of the US, but the constitution doesn't give you the power to buy the land",
    img: "napoleonfat.jpeg",
    options: [
      {text: "I am the constitution, buy the land anyway", value:25},
      {text: "Stick to your beliefs about limited government power and turn down the deal", value: 10},
      
    ]
  },
  {
    prompt: "Britain and France are attacking US trading ships",
    img: "thequeen.jpeg",
    options: [
      {text: "Declare war and send the navy after them", value: 5},
      {text: "Ban trade with all foreign nations, quite possibly destroying your own economy and making everyone hate you", value: -50},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const USPresidentSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(0)
  const [diff, setDiff] = useState(0)
  const highApproval = prob > 50

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>American President Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/flag15.png" alt="USA Flag (15 states)"></img>

      <h3 css={css`margin-bottom: 5px`}>Your goal is to get a high approval rating</h3>
      <label for="file">Approval Rating ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 100px;
    margin-top: 15px;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : highApproval ?  (
    <div css={css`text-align: center;`}>
      <h2>You were a great president</h2>
      <img css={css`
        max-width: 400px;
      `} src="/jeffy.jpeg"/>
      <h3>Good job!</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>Some people love you, some people hate you</h2>
      <img css={css`
       max-width: 400px;
      `} src="/jeffysad.png"/>
      <h3>It be like that sometimes</h3>
    </div>}

</div>
    </div>
  )
}
  export default USPresidentSimulator;